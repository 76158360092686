import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import SearchBox from "../components/SearchBox";
import BookList from "../components/BookList";
import BookCards from "../components/BookCards";
import Sidebar from "../components/Sidebar";
import LatestUpdateSide from "../components/LatestUpdateSide";
import Hero from "../components/Hero";
import DisplayVertical from "../components/ads/DisplayVertical";
import DisplayHorizontal from "../components/ads/DisplayHorizontal";
import InFeed from "../components/ads/InFeed";
import DisplaySquare from "../components/ads/DisplaySquare";
import InArticle from "../components/ads/InArticle";
import DisplayHorizontal_monumetric from "../components/ads/DisplayHorizontal_monumetric";

function SearchingPage() {
    document.title = "Search Books";

    const [pageNum, setPageNum] = useState(1);
    const [searchData, setSearchData] = useState({
        page: 1, 
        pageSize: 10, 
        diversity: "All", 
        original_language: "All", 
        completion_status: "All", 
        ending: "All", 
        word_count: "All",
        setting: [], 
        genre: [], 
        tag: [], 
        book_name: "",
        sort_by: "newest"
    }); 

    const [searchBookMessage, setSearchBookMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Define the API fetch function using searchData
    const fetchBooks = async () => {
        setIsSubmitting(true);
        try {
            setSearchBookMessage('');
            const query = new URLSearchParams(searchData).toString();
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchbook?${query}`);
            setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 429) {
                setSearchBookMessage(error.response.data.message);
            } else {
                setSearchBookMessage("An error occurred, please try again later.");
            }
            throw error; // Ensure the error is thrown so useQuery can handle it
        } finally {
            setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
        }
    };

    // Setup the useQuery hook
    const { data, error, isLoading, isError, refetch } = useQuery(
        ['books', searchData],  // Depend on searchData to refetch
        fetchBooks, 
        {
            refetchOnWindowFocus: false,
            staleTime: 300000,  // 5 minutes
            cacheTime: 1800000, // 30 minutes
            retry: false
        }
    );

    // Effect to update searchData when pageNum changes
    useEffect(() => {
        setSearchData(prevData => ({
            ...prevData,
            page: pageNum,
        }));
    }, [pageNum]);

    // Effect to trigger refetch whenever searchData changes
    useEffect(() => {
        refetch();
    }, [searchData]);

    const handleSearchData = (searchCondition) => {
        setSearchData(prevData => {
            const updatedData = { ...prevData, ...searchCondition };
            if (JSON.stringify(updatedData) !== JSON.stringify(prevData)) {
                setPageNum(1); // Reset page number to 1
            }
            return updatedData;
        });
    };

    const handlePageChange = (newPageNum) => {
        setPageNum(newPageNum);
    }

    let pages = "";

    if (!isLoading && !isError && data) {
        pages = [...Array(data.totalPages).keys()].map(n => n + 1); // Create an array from 1 to totalPages
    }
    
    return (
        <div className={"flex flex-col justify-center items-center my-5 max-w-full" + (isLoading || isError ? "bg-gray-300" : "")}>
            <Helmet>
                <meta name="description" content="Searching books based on certain criterias" />
                <title>Searching Books</title>
            </Helmet>
            <SearchBox onSearchData={handleSearchData} searchBookMessage={searchBookMessage} isSubmitting={isSubmitting} />

            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start m-5 max-w-full'>
                <div className='flex flex-col justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                    {
                        isLoading ? <div>Loading...</div> : 
                        isError ? <div>Error: {error.message}</div> : 
                            <div className="flex flex-col max-w-full">
                                <InArticle />
                                {/* <DisplayHorizontal_monumetric /> */}
                                <h1 className='mb-3 text-3xl font-bold text-center'>Showing {data.totalBooks} results</h1>
                                <div className='flex justify-center items-center max-w-full'>
                                    <BookCards book_list={data.books} />
                                </div>
                                <div className="flex justify-center items-center max-w-full gap-2">
                                    {pages.map((page) => (
                                        <button
                                            key={page}
                                            className={`px-3 py-1 ${pageNum === page ? '' : 'text-red-500 hover:underline'} font-bold`}
                                            onClick={() => handlePageChange(page)}
                                            disabled={pageNum === page}
                                        >
                                            {page}
                                        </button>
                                    ))}
                                </div>
                                <InArticle />
                                {/* <DisplayHorizontal_monumetric /> */}
                            </div>
                    }
                </div>
                <div className='flex flex-col justify-center items-center max-w-full lg:max-w-xs lg:mr-3'>
                    <Sidebar />
                    <LatestUpdateSide />
                </div>
            </div>
            
        </div>
    )
}

export default SearchingPage;