import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { useQuery } from 'react-query';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import BookDetails from "../components/BookDetails";
import BookChaptersList from "../components/BookChaptersList";
import BookCards from "../components/BookCards";
import Sidebar from "../components/Sidebar";
import LatestUpdateSide from "../components/LatestUpdateSide";
import Hero from "../components/Hero";
import DisplayVertical from "../components/ads/DisplayVertical";
import DisplayHorizontal from "../components/ads/DisplayHorizontal";
import DisplayHorizontal_monumetric from "../components/ads/DisplayHorizontal_monumetric";
import DisplaySquare from "../components/ads/DisplaySquare";
import InFeed from "../components/ads/InFeed";
import InArticle from "../components/ads/InArticle";
import Remark42Comments from "../components/Remark42Comments";
import CommentSection from "../components/comments/CommentSection";

function BookDetailsPage () {
    const [bookName, setBookName] = useState(null);
    const [bookDescription, setBookDescription] = useState(null);
    const { bookIDSlug } = useParams();
    const bookID = bookIDSlug.split('-')[0];

    // Define the API fetch function using searchData
    const fetchBooks = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${bookIDSlug}`);
        if (response.data) {
            setBookName(response.data.bookDetails.english_book_name);  // Update the book name
            setBookDescription(response.data.bookDetails.description);  // Update the book description
        }
        
        return response.data;
    };

    // Setup the useQuery hook
    const { data, error, isLoading, isError, refetch } = useQuery(
        [bookIDSlug],  // Depend on searchData to refetch
        fetchBooks, 
        {
            refetchOnWindowFocus: false,
            staleTime: 300000,  // 5 minutes
            cacheTime: 1800000, // 30 minutes
        }
    );

    useEffect(() => {
        fetchBooks();  // Call the function to fetch book data
    }, [bookIDSlug]);

    if (isLoading) return <div>Loading</div>;
    if (isError) return <div>Error</div>;

    return (
        <div className="flex flex-col max-w-full">
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start mx-3 my-5 gap-10 max-w-full'>
                <Helmet>
                    <meta name="description" content={bookDescription} />
                    <title>{bookName}</title>
                </Helmet>
                <div className="flex flex-col gap-5 max-w-full">
                    <DisplayHorizontal />
                    {/* <DisplayHorizontal_monumetric /> */}
                    <BookDetails bookData={data.bookDetails} />

                    <DisplayHorizontal />
                    {/* <DisplayHorizontal_monumetric /> */}
                    
                    <h2 className="text-3xl font-bold mb-4 text-center">Chapters List</h2>
                    <BookChaptersList bookIDSlug={bookIDSlug} bookChapters={data.chapters}/>

                    {/* <div className='flex flex-col justify-center items-center w-full'>
                        <h1 className='mb-3 text-3xl text-center font-bold underline'>Comments</h1>
                        <Remark42Comments bookID={bookID} />
                    </div>

                    <DisplayHorizontal /> */}
                    <CommentSection book_id={bookID} chapter_id={null} />
                    
                    <div className='grid lg:gird-col-2 justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                        <h1 className='mb-3 text-3xl text-center font-bold underline'>Recommendations</h1>
                        <div className='flex'>
                            <BookCards book_list={data.randomBooks} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col justify-start items-start max-w-full lg:max-w-xs'>
                    <Sidebar />
                    <LatestUpdateSide />
                </div>
            </div>
        </div>
    );
}

export default BookDetailsPage;